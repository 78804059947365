import { useEffect, useState } from "react";
import { apiGet } from "./Actions";
import Loader from "./Loader";
import EventBlock from "./EventBlock";

const Homepage = () => {
  const [data, setData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(-1);
  const [selectedCategoryName, setSelectedCategoryName] = useState("Vše");

  useEffect(() => {
    fetch();
  }, []);

  const fetch = () => {
    apiGet(
      (data) => {
        setData(data.data);
      },
      (error) => {
        console.log("ERROR: ", error);
      }
    );
  };

  const prepareCategories = () => {
    let arr = [];
    if (data) {
      if (data.length > 1) {
        arr.push(
          <div
            key={-1}
            className={
              "category-item" + (selectedCategory === -1 ? " selected" : "")
            }
            onClick={() => {
              setSelectedCategory(-1);
              setSelectedCategoryName("Vše");
            }}
          >
            <span>Vše</span>
          </div>
        );
        data.map((item, index) => {
          return arr.push(
            <div
              key={index}
              className={
                "category-item" +
                (selectedCategory === index ? " selected" : "")
              }
              onClick={() => {
                setSelectedCategory(index);
                setSelectedCategoryName(item.name);
              }}
            >
              <span>{item.name}</span>
            </div>
          );
        });
        return arr;
      }
    }
  };

const prepareData = () => {
    let arr = [];
    if (data) {
        data.map((category) => {
                category.subject.map((subject, index) => {
                    arr.push(
                        <div className={"col-lg-4 col-md-6 mb-4"} key={index}>
                            <EventBlock
                                name={subject.name}
                                image={subject.urlImage}
                                link={subject.urlCanal}
                                category={category.name}
                            />
                        </div>
                    );
                });
        });
        return arr;
    } else {
        return (
            <div className={"col-lg-12 text-center"}>
                <Loader />
            </div>
        );
    }
};

  return (
    <div className="App">
      <main className="main-content" role="main">
        <div className="container">
          <div className="container">
            <div className="eventlist">
              <div className="row">{prepareData()}</div>
            </div>
          </div>
        </div>
        <section id="douwant">
          <div className="container-fluid px-0">
            <div className="row no-gutters">
              <div className="col-lg-12 text-center">
                <h2>Chcete nás?</h2>
              </div>
            </div>
          </div>
        </section>
        <section id="products">
          <div className="container-fluid px-0">
            <div className="row no-gutters">
              <div className="col-lg-12">
                <div className="row no-gutters">
                  <div className="col-lg-12 text-center">
                    <a
                      href="https://www.enigoo.cz/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="enigoo-product-item enigoo">
                        <div className="enigoo-product-item_content">
                          <img
                            src="https://market.enigoo.cz/img/enigoo_purple.svg"
                            draggable="false"
                            alt="enigoo"
                          />
                          <p>Služba predaja vstupeniek pre náročných.</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Homepage;
