import axios from "axios";

export const instance = axios.create({
    baseURL: "https://webadmin.enigoo.cz",
    headers: {
        'Content-Type': 'application/json',
        'Token': "p1tpTPer9NirqLoWASX59yQYUeXEiMrsVVDChL9Y5kld9unOK8Lk6Ds1TNEjEYxL6fxQcJmXFHXPAKqPv4ZWK8iSmqym"
    }
})

export const onErrorFunction = (error) => {
    if (error?.response.data){
        return error.response.data
    }else{
        return {type: null, message: null, data: null}
    }
}