

const Menu = () => {
    return(
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container"><a href="/" className="navbar-brand"><img
                src="https://market.enigoo.cz/img/enigoo_purple.svg" alt="Logo"/>
                <sup>market</sup></a>
                <button aria-controls="basic-navbar-nav" type="button" data-bs-toggle="collapse" data-bs-target="#basic-navbar-nav" aria-label="Toggle navigation" className="navbar-toggler collapsed">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="justify-content-end navbar-collapse collapse" id="basic-navbar-nav">
                    <div className="navbar-nav">

                    </div>
                </div>
            </div>
        </nav>
    )
}
export default Menu