import './App.css';
import Homepage from "./pages/Homepage";
import Menu from "./pages/Menu";
import Footer from "./pages/Footer";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Detail from "./pages/Detail";

function App() {

    return (
        <BrowserRouter>
            <Menu/>
            <Routes>
                <Route path={"/"} element={<Homepage />} />
                <Route path={"/vstupenky/:url"} element={<Detail />} />
            </Routes>
            <Footer/>
        </BrowserRouter>
    );
}

export default App;
