import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";

import {Button} from "react-bootstrap";

const Detail = () => {
    const {url} = useParams()
    const [_error, _setError] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const handleClick = () => {
        setLoading(true)
    }

    if (_error) return (
        <div className="container-fluid px-0">
            <div className="row no-gutters">
                <div className="col-lg-12">
                    <div className="row no-gutters">
                        <div className="col-lg-12 text-center py-5">
                            <p>Pri načítavaní došlo k chybe, zopakujte nižšie uvedenú akciu</p>
                            <p><Button id={"btn-retry"} onClick={handleClick} type={"button"} disabled={loading}>Vyskúšajte
                                znova</Button></p>
                            <p>alebo</p>
                            <Link to={"/"}><Button id={"btn-retry"} type={"button"}>Späť na hlavnú stránku</Button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <iframe src={`${decodeURIComponent(url)}`} width={"100%"} height={"780px"} title="Prodejní aplikace" id="top"></iframe>
    )
}
export default Detail
