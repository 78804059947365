import {Link} from "react-router-dom";

const EventBlock = ({name, image, link, category}) => {
    const displayName = name.length > 30 ? name.substring(0, 30) + "..." : name;

    return(
        <div className={"EventBlock shadowed"}>
            <Link target={"_blank"} to={link}>
                <div className={"EventBlock_wrapper"}>
                    <div className={"EventBlock_category shadowed"}>{category}</div>
                    <div className={"EventBlock_header"}>
                        <div className={"EventBlock_header_image"} style={{background: `url(${image})`}}></div>
                    </div>
                    <div className={"EventBlock_body"}>
                        <div className={"EventBlock_name"}>
                            <h2>{displayName}</h2>
                        </div>
                        <div className={"EventBlock_buy"}>
                            <span>Vstupenky</span>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
}
export default EventBlock;
