import { instance, onErrorFunction } from "../config/axios-cnf";
import axios from "axios";

export const apiGet = (callback, error) => {
  axios
    .get(
      "https://market.enigoo.sk/api/get?language=EUR"
    )
    .then((response) => callback(response))
    .catch((err) => error(onErrorFunction(err)));
};
