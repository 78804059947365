
const Loader = () => {
    return(
        <div className={"loading-spinner"}>
            <div className={"loading-spinner_loader"}>
            </div>
            <img src="https://market.enigoo.cz/img/enigoo_purple.svg" alt="Engioo"/>
        </div>
    )
}
export default Loader
